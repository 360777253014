import React, { Component } from "react";
import { withRouter } from "react-router";
import TransitionVideo from "../assets/videos/ChromiumDoorAnimation.mp4";
import "../css/transition.css";
import ReactPlayer from "react-player";

class TransitionContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleEnded = (event) => {
    const { history } = this.props;
    history.push(
      `/scene/${this.props.match.params.id}/${this.props.match.params.prev_id}`
    );
  };

  render() {
    const style = {
      width: parseInt(this.props.match.params.width),
      height: parseInt(this.props.match.params.height),
      alignSelf: "center !important",
    };

    return (
      <div
        className="d-flex justify-content-center"
        style={{ overflow: "hidden" }}
      >
        <div
          className="transition-container d-flex align-self-center transition-enabled"
          style={style}
        >
          <ReactPlayer
            playsInline
            playing={true}
            id={this.props.child ? this.props.child.id : null}
            width="100%"
            height="100%"
            onEnded={this.props.onEnded}
            url={TransitionVideo}
            ref={(video) => (this.video = video)}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            onEnded={() => this.handleEnded()}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(TransitionContainer);
