import React from "react";
import BackButtonSVG from "../assets/buttons/btn_back.svg";
import Image from "react-bootstrap/Image";
import { useHistory } from "react-router-dom";
import ReplayBtnSVG from "../assets/buttons/btn_video_replay.svg";
import CloseBtnSVG from "../assets/buttons/btn_close_x.svg";

export const BackBtn = (props) => {
  const style = {
    height: "100%",
    width: "100%",
  };

  let history = useHistory();

  return (
    <>
      <div id="back-button" onClick={() => history.push(`/scene/${props.id}`)}>
        <Image src={BackButtonSVG} alt="back_button" style={style} />
      </div>
      ;
    </>
  );
};

export const ReplayBtn = (props) => {
  return (
    <>
      <div
        className="result-menu-btn p-2"
        id="replay-video"
        data-video-id=""
        onClick={() => props.onClick()}
      >
        <Image src={ReplayBtnSVG} alt="replay-btn" />
      </div>
    </>
  );
};

export const CloseBtn = (props) => {
  const { type, id } = props
  let history = useHistory();

  return (
    <>
      <div
        className="result-menu-btn d-flex p-2 mr-5"
        onClick={() => history.push(`/${type}/${id}`)}
        id="close-result"
      >
        <Image src={CloseBtnSVG} />
      </div>
    </>
  );
};

const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

export const ExternalLinkBtn = (props) => {
  const { link, text } = props

  return (
    <>
      <div
        className="external-button d-flex p-2 mr-5"
        onClick={() => openInNewTab(link)}
        id="close-result"
      >
        <span className="flex external-button-text">{text}</span>
      </div>
    </>
  );
}