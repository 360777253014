import React, { Component } from "react";
import Resources from "../data/resources.json";

class ResourceList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        {Resources.map((resource) => (
          <div className="d-flex p-2" key={resource.pk}>
            <a
              className="resource-link"
              href={resource.fields.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {resource.fields.name}
            </a>
          </div>
        ))}
      </div>
    );
  }
}

export default ResourceList;
