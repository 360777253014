import React, { Component } from "react";
import { mat2 } from "gl-matrix";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Content as TooltipContent } from "./HotspotRollover"

import Image from "react-bootstrap/Image";
import hotspotImg from "../assets/buttons/Hotspot.svg";

import Tooltips from "../data/tooltips.json";
import Results from "../data/results.json";

class Hotspot extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.ref = React.createRef()

    this.imageClick = this.imageClick.bind(this);
  }

  imageClick = (event) => {
    document.body.click()
  }

  render() {
    var destViewportPix = [
      (this.props.scene_width * 16.0) / 9.0,
      this.props.scene_height,
    ];
    var vidContSize = [this.props.scene_width, this.props.scene_height];
    // Set the last two values to zero, we dont need them, we only need one quadrant
    var hotSpotPos = mat2.fromValues(
      this.props.hotspot_x_location,
      this.props.hotspot_y_location,
      0,
      1.0
    );

    // Index 0 = width
    // Index 1 = height
    var hotSpotPosPix = [
      ((hotSpotPos[0] + 1.0) / 2.0) * destViewportPix[0] +
        (destViewportPix[0] - vidContSize[0]) * -0.5,
      (1.0 - (hotSpotPos[1] + 1.0) / 2.0) * destViewportPix[1] +
        (destViewportPix[1] - vidContSize[1] * 1.0) * -0.5,
    ];

    const style = {
      left: hotSpotPosPix[0],
      top: hotSpotPosPix[1],
    };

    // Get Tooltip
    var tooltip;
    for (let i = 0; i < Tooltips.length; i++) {
      if (Tooltips[i].pk === this.props.tooltip_id) {
        tooltip = Tooltips[i];
      }
    }
    let results = [];
    tooltip.fields.results.forEach(id => {
      for (let i = 0; i < Results.length; i++) {
        if (Results[i].pk === id) {
          results.push(Results[i]);
        }
      }
    })

    const popover = (
      <Popover id="popover-basic" >
        <Popover.Title as="h2">{tooltip.fields.title}</Popover.Title>
        <Popover.Content>
          <span>{tooltip.fields.text}</span>
          {<TooltipContent onClick={(e) => {this.imageClick(e)}} results={results} />}
        </Popover.Content>
      </Popover>
    );

    var placement = tooltip.fields.left ? "left" : "right";

    return (
      <OverlayTrigger trigger="click" rootClose={true} placement={placement} overlay={popover}>
        <div
          className="hotspotDiv"
          data-type={this.props.type}
          data-next-page={this.props.nextPage}
          data-x-location={this.props.hotspot_x_location}
          data-y-location={this.props.hotspot_y_location}
          data-popover-title={this.props.tooltip_id}
          data-popover-content={this.props.tooltip_id}
          style={style}
        >
          <Image src={hotspotImg} alt="hotspot" />
        </div>
      </OverlayTrigger>
    );
  }
}

export default Hotspot;
