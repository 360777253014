import React from "react";
import hotspotImg from "../assets/buttons/Hotspot.svg";
import Image from "react-bootstrap/Image";

const RolloverInstructions = () => {
  return (
    <>
      <div id="rollover-instructions" className="rolloverinstructions">
        <div className="rolloverinstructions">
            <Image id="rollover-image" src={hotspotImg} alt="hotspot" />
        </div>
        <div id="rollover-text" className="rolloverinstructions">
            <span className="rolloverinstructions">Click on the hotspots to take a tour</span>
        </div>
      </div>
    </>
  );
};

export default RolloverInstructions;
