import React, { Component } from "react";
import FAQClosed from "../assets/buttons/faq_closed.svg";
import FAQOpened from "../assets/buttons/faq_opened.svg";
import "../css/faq.css";

class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { faq, open, toggleOpen } = this.props;

    return (
      <div>
        <div className="d-flex p-2 question-text">
          <div className="d-flex arrow-wrapper pr-2 flex-column">
            {!open && (
              <div className="faq-arrow arrow-closed">
                <img
                  src={FAQClosed}
                  style={{ width: "100%", height: "100%" }}
                  onClick={() => toggleOpen(faq.pk)}
                  alt="Closed"
                />
              </div>
            )}
            {open && (
              <div className="faq-arrow arrow-opened">
                <img
                  src={FAQOpened}
                  style={{ width: "100%", height: "100%" }}
                  onClick={() => toggleOpen(faq.pk)}
                  alt="Opened"
                />
              </div>
            )}
          </div>
          <p style={{ marginBottom: 0 }}>{faq.fields.question_text}</p>
        </div>
        <div className="d-flex p-2 answer-wrapper">
          <div className="d-flex">
            <div
              className={`answer-text answer-text-nowrap ${
                open ? "white-space-normal" : ""
              }`}
            >
              {faq.fields.answer_text}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FAQ;
