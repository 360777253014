import React, { Component } from "react";
import CloseBtn from "../assets/buttons/btn_slide_close.svg";

class SlideOut extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className={`overlay-details left-slide slide-out ${
          this.props.visible ? "slide-in" : "slide-away"
        } ${this.props.video ? "video-mode slide-slow" : ""}`}
      >
        <div
          className={`d-flex flex-column ${
            this.props.m_auto ? "justify-contents-center" : ""
          }`}
          style={{ height: "100%", width: "100%" }}
        >
          {this.props.onDismiss && (
            <div
              className="d-flex flex-row-reverse"
              style={{ height: "10%", width: "100%" }}
            >
              <div
                className="d-flex m-3 close-left-slide"
                style={{ width: "10%", height: "35%" }}
              >
                <img
                  className="menu-icon"
                  src={CloseBtn}
                  alt="close"
                  onClick={this.props.onDismiss}
                />
              </div>
            </div>
          )}
          <div
            className={`d-flex flex-column ${
              this.props.m_auto
                ? "m-auto justify-contents-center align-self-center contact-us"
                : "p-2 pl-5"
            }`}
          >
            {this.props.title && (
              <div className="d-flex p-2 slideout-title">
                <p>{this.props.title}</p>
              </div>
            )}
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

export default SlideOut;
