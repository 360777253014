import Image from "react-bootstrap/Image";
import { useHistory } from "react-router-dom";
import "../css/rollover.css";

const style = {
  width: "100%",
};

const imageClick = (props) => {
  props.onClick(props.event)
  props.history.push(`/results/${props.result.pk}`)
}

export const Content = (props) => {
  let history = useHistory();
  const { results, onClick } = props
  const tooltipContent = results.map((result, key) => {
    return (
      <div key={key} id={result.fields.name} className="col-md-6 tooltip-image" onClick={(event) => imageClick({ history, onClick, result, event })}>
        <Image src={result.fields.thumbnail} alt="back_button" style={style} />
        <span>{result.fields.name}</span>
      </div> 
    )
  })
  return (
    <>
      <div className="row tooltip-images">
        {tooltipContent}
      </div>
    </>
  )
}