import React, { Component } from "react";
import "../css/thankyou.css";
import "../css/contactus.css";

class ThankYou extends Component {
  render() {
    const wrapper_style = {
      height: "100%",
    };
    return (
      <>
        <div
          className="d-flex flex-column justify-content-center"
          style={wrapper_style}
        >
          <div className="d-flex flex-column align-self-center">
            <div className="d-flex p-2 thankyou-title align-self-center">
              <p>{this.props.title}</p>
            </div>
            <div className="d-flex p-2 thankyou-text align-self-center">
              <p>{this.props.subtitle}</p>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <button
            className="btn btn-primary custom-btn"
            onClick={() => this.props.onClick()}
          >
            Back to FAQ
          </button>
        </div>
      </>
    );
  }
}

export default ThankYou;
