import React, { Component } from "react";
import { Form } from "react-bootstrap";
import "../css/contactus.css";

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      institution: "",
      email: "",
      comment: "",
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    // Form data to be sent
    // console.log(this.state);

    this.props.handleSubmit(this.state);
    this.setState({
      first_name: "",
      last_name: "",
      institution: "",
      email: "",
      comment: "",
    });
  };

  render() {
    return (
      <>
        <Form>
          <Form.Group controlId="formFirstName">
            <Form.Control
              name="first_name"
              placeholder="First name"
              value={this.state.first_name}
              onChange={(e) => this.handleChange(e)}
            />
          </Form.Group>
          <Form.Group controlId="formLastName">
            <Form.Control
              name="last_name"
              placeholder="Last name"
              value={this.state.last_name}
              onChange={(e) => this.handleChange(e)}
            />
          </Form.Group>
          <Form.Group controlId="formInstitution">
            <Form.Control
              name="institution"
              placeholder="Institution"
              value={this.state.institution}
              onChange={(e) => this.handleChange(e)}
            />
          </Form.Group>
          <Form.Group controlId="formEmail">
            <Form.Control
              name="email"
              placeholder="Email"
              value={this.state.email}
              onChange={(e) => this.handleChange(e)}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Control
              as="textarea"
              rows="12"
              name="comment"
              value={this.state.comment}
              onChange={(e) => this.handleChange(e)}
              placeholder="Type your comment here"
            />
          </Form.Group>
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-primary custom-btn"
              onClick={(e) => this.handleSubmit(e)}
            >
              Submit
            </button>
          </div>
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-link"
              value="Cancel"
              onClick={this.props.onClick}
            >
              Cancel
            </button>
          </div>
        </Form>
      </>
    );
  }
}

export default ContactUs;
