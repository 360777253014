import React, { Component } from "react";
import Logo from "../assets/logos/10x_Logo_slide_out.svg";
import Descriptions from "../data/descriptions.json";
import Bullets from "../data/bullets.json";
import "../css/result.css";

class ResultDescription extends Component {
  render() {
    const { result } = this.props;
    const descriptions = Descriptions.filter(
      (description) => description.fields.result === result.pk
    );

    return (
      <div
        className={
          result.fields.is_spec ? "spec-container" : "description-container"
        }
      >
        {descriptions.map((description, index) => (
          <div key={index}>
            <div
              className={`description ${
                result.fields.is_spec ? "spec-title" : "description-title"
              }`}
            >
              {description.fields.title}
            </div>
            {description.fields.subtitle && (
              <div
                className={`description ${
                  result.fields.is_spec
                    ? "spec-subtitle"
                    : "description-subtitle"
                }`}
              >
                {description.fields.subtitle}
              </div>
            )}
            <div
              className={`description ${
                result.fields.is_spec ? "spec-bullets" : "description-bullets"
              }`}
            >
              <ul>
                {Bullets.filter(
                  (bullet) =>
                    description.fields.bullet.indexOf(bullet.pk) !== -1
                ).map((bullet) => (
                  <li key={bullet.pk}>{bullet.fields.text}</li>
                ))}
              </ul>
            </div>
          </div>
        ))}
        <div className="d-flex flex-row-reverse logo-results">
          <img src={Logo} style={{ width: "100%", height: "99%" }} alt="stuff" />
        </div>
      </div>
    );
  }
}

export default ResultDescription;
