/* eslint-disable eqeqeq */
import React, { Component } from "react";
import "../css/scene.css";
import Scene from "./Scene";
import Scenes from "../data/scenes.json";

class SceneContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let scene;

    for (var i = 0; i < Scenes.length; i++) {
      if (Scenes[i].pk == this.props.match.params.id) {
        scene = Scenes[i];
      }
    }

    return (
      <div className="scene-container d-flex justify-content-center transition-enabled">
        <Scene backgroundImg={scene.fields.background_img} scene={scene} />
      </div>
    );
  }
}

export default SceneContainer;
