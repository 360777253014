import React, { Component } from "react";
import ReactPlayer from "react-player";
import ResultMenu from "./ResultMenu";
import "../css/video.css";

class VideoOverlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoPlaying: false,
      isVideoMuted: false
    };

    this.replayVideo = this.replayVideo.bind(this);
    this.onReady = this.onReady.bind(this);
  }

  replayVideo = () => {
    this.video && this.video.seekTo(0);
    this.setState({
      videoPlaying: true,
    });

    if (this.props.replayVideo) {
      this.props.replayVideo();
    }
  };

  onReady = () => {
    const isChrome = !!window.chrome;

    this.setState({
      isVideoMuted: isChrome
    })
  };

  render() {
    const { result_menu, type, id } = this.props;

    return (
      <div
        className="video-container"
        data-transition-id={
          this.props.child ? this.props.child.transition_video : null
        }
      >
        <ReactPlayer
          playsInline
          preload="auto"
          playing={this.state.videoPlaying || this.props.autoPlay}
          id={this.props.child ? this.props.child.id : null}
          data-video-id={
            this.props.child ? this.props.child.transition_video : null
          }
          width="100%"
          height="100%"
          onEnded={this.props.onEnded}
          url={this.props.video}
          controls={this.props.controls}
          ref={(video) => (this.video = video)}
          onReady={this.onReady}
          muted={this.state.isVideoMuted}
        />

        {result_menu && <ResultMenu type={type} id={id} replayClick={() => this.replayVideo()} />}
      </div>
    );
  }
}

export default VideoOverlay;
