import React, { Component } from "react";
import FAQs from "../data/faqquestions.json";
import FAQ from "./FAQ.js";
import "../css/faq.css";

class FAQList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openQuestions: [],
    };
  }

  toggleQuestion = (id) => {
    const index = this.state.openQuestions.indexOf(id);
    if (index !== -1) {
      this.setState((state) => {
        const openQuestionsCopy = [...state.openQuestions];
        openQuestionsCopy.splice(index, 1);
        return {
          openQuestions: openQuestionsCopy,
        };
      });
    } else {
      this.setState((state) => {
        const openQuestionsCopy = [...state.openQuestions];
        openQuestionsCopy.push(id);

        return {
          openQuestions: openQuestionsCopy,
        };
      });
    }
  };

  render() {
    return (
      <div>
        <div id="faq-content">
          {FAQs.map((faq) => (
            <FAQ
              faq={faq}
              open={this.state.openQuestions.indexOf(faq.pk) !== -1}
              toggleOpen={this.toggleQuestion}
              key={faq.pk}
            />
          ))}
        </div>
        <div
          id="question-btn"
          className="d-flex justify-content-center p-3 mt-4"
        >
          <button
            type="button"
            className="btn btn-primary custom-btn"
            onClick={() => this.props.contactUsClick()}
          >
            Contact Us
          </button>
        </div>
      </div>
    );
  }
}

export default FAQList;
