import React, { Component } from "react";
import VideoOverlay from "./VideoOverlay";
import SlideOut from "./SlideOut";
import ResultDescription from "./ResultDescription";
import Results from "../data/results.json";
import "../css/result.css";

class Result extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoEnded: false,
    };

    this.onVideoEnd = this.onVideoEnd.bind(this);
    this.onReplayVideo = this.onReplayVideo.bind(this);
  }

  onVideoEnd = () => {
    this.setState({ videoEnded: true });

    if (this.props.videoEnded) {
      this.props.videoEnded();
    }
  };

  onReplayVideo = () => {
    this.setState({ videoEnded: false });
  };

  render() {
    const result = Results.find(
      (result) => result.pk === parseInt(this.props.match.params.id)
    );

    return (
      <div className="video-wrapper">
        <VideoOverlay
          child={this.props.child ? this.props.child : null}
          // onEnded={this.onVideoEnd}
          video={result.fields.video}
          autoPlay={true}
          controls={true}
          result_menu={true}
          replayVideo={this.onReplayVideo}
          type="scene"
          id={1}
        />
        <SlideOut visible={this.state.videoEnded} video={true}>
          <ResultDescription result={result} />
        </SlideOut>
      </div>
    );
  }
}

export default Result;
